import * as React from "react"
import remark from "remark"
import html from "remark-html"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { render } from "react-dom"

export const PageQuery = graphql`
  query PrivacyQuery {
    strapi {
      privacy {
        title
        privacy
        updated_at
      }
      employees {
        name
        email
        phone
        position
        image {
          id
          staticFile {
            childImageSharp {
              gatsbyImageData(width: 80, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`

const PrivacyPage = ({ data }) => {
  const privacy = data.strapi.privacy
  const updatedAt = new Date(privacy.updated_at)

  const [renderedHtml, setRenderedHtml] = React.useState("")

  React.useEffect(() => {
    let isSub = true
    remark()
      .use(html)
      .process(privacy.privacy)
      .then(file => setRenderedHtml(file.contents))
    return () => {
      isSub = false
    }
  }, [])

  return (
    <Layout>
      <SEO title="Privatlivspolitik" />

      <div className="bg-gray-100">
        <div className="container mx-auto">
          <div className="py-16 pt-32 max-w-2xl mx-auto text-center">
            <h1 className="heading-xl">Privatlivspolitik</h1>
            <span>
              Sidst opdateret {updatedAt.getDate()}.{updatedAt.getMonth() + 1}.
              {updatedAt.getFullYear()}
            </span>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div
          className="mx-auto max-w-2xl py-16 markdown"
          dangerouslySetInnerHTML={{
            __html: renderedHtml,
          }}
        />
      </div>
    </Layout>
  )
}

export default PrivacyPage
